$text-color:            #222 !default;
$text-color-light:      #373737 !default;
$background-color:      #ecdbbb !default;
$code-background-color: #fbf1c7 !default;

$border-color:          mix(#000, $background-color, 20%) !default;
$border-color-light:    lighten($border-color, 10%) !default;
$border-color-dark:     darken($border-color, 25%) !default;

$link-base-color:       #760000 !default;
$link-visited-color:    darken($link-base-color, 5%) !default;

$table-text-color:      lighten($text-color, 18%) !default;
$table-zebra-color:     mix($background-color, $border-color, 80%) !default;
$table-header-bg-color: mix($background-color, $border-color, 70%) !default;
$table-header-border:   $border-color !default;
$table-border-color:    $border-color-light !default;

$pre-shadow:            0 0 4px rgba(34,34,34,0.4) inset !default;
$dropdown-shadow:       0px 8px 16px 0px rgba(0,0,0,0.2) !default;

$subscribe-color:       #004e00 !default;

// Generated by running `rougify style gruvbox.light`; The version of rougify is 3.15.0
// =============================================================================
.highlight table td { padding: 5px; }
.highlight table pre { margin: 0; }
.highlight, .highlight .w {
  color: #282828;
  background-color: #fbf1c7;
}
.highlight .err {
  color: #9d0006;
  background-color: #fbf1c7;
  font-weight: bold;
}
.highlight .c, .highlight .ch, .highlight .cd, .highlight .cm, .highlight .cpf, .highlight .c1, .highlight .cs {
  color: #928374;
  font-style: italic;
}
.highlight .cp {
  color: #427b58;
}
.highlight .nt {
  color: #9d0006;
}
.highlight .o, .highlight .ow {
  color: #282828;
}
.highlight .p, .highlight .pi {
  color: #282828;
}
.highlight .gi {
  color: #79740e;
  background-color: #fbf1c7;
}
.highlight .gd {
  color: #9d0006;
  background-color: #fbf1c7;
}
.highlight .gh {
  color: #79740e;
  font-weight: bold;
}
.highlight .k, .highlight .kn, .highlight .kp, .highlight .kr, .highlight .kv {
  color: #9d0006;
}
.highlight .kc {
  color: #8f3f71;
}
.highlight .kt {
  color: #b57614;
}
.highlight .kd {
  color: #af3a03;
}
.highlight .s, .highlight .sa, .highlight .sb, .highlight .sc, .highlight .dl, .highlight .sd, .highlight .s2, .highlight .sh, .highlight .sx, .highlight .s1 {
  color: #79740e;
  font-style: italic;
}
.highlight .si {
  color: #79740e;
  font-style: italic;
}
.highlight .sr {
  color: #79740e;
  font-style: italic;
}
.highlight .se {
  color: #af3a03;
}
.highlight .nn {
  color: #427b58;
}
.highlight .nc {
  color: #427b58;
}
.highlight .no {
  color: #8f3f71;
}
.highlight .na {
  color: #79740e;
}
.highlight .m, .highlight .mb, .highlight .mf, .highlight .mh, .highlight .mi, .highlight .il, .highlight .mo, .highlight .mx {
  color: #8f3f71;
}
.highlight .ss {
  color: #076678;
}
